import { jsx as _jsx } from "react/jsx-runtime";
import { prodProfileUrl, stageProfileUrl } from '@const';
import { checkProdMode } from '@utils/checkProdMode';
import { render } from 'react-dom';
import App from './components/App';
import { checkDevMode } from '@utils/checkDevMode';
import { store } from '@store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// виджет профиля
const createProfileWidget = () => {
    const isProd = checkProdMode();
    const script = document.createElement('script');
    script.src = isProd ? prodProfileUrl : stageProfileUrl;
    script.onload = () => store.dispatch.userModel.setProfileWidgetLoaded();
    document.body.appendChild(script);
};
// модуль опросов
const createSurveyWidget = () => {
    const script = document.createElement('script');
    script.src = 'https://mtsopros.mts.ru/libsdk/sdk.v6.js';
    script.onload = () => store.dispatch.surveyWidgetModel.setSurveyWidgetLoaded();
    document.body.appendChild(script);
};
const createGTM = () => {
    const dataLayer = document.createElement('script');
    const script = document.createElement('script');
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe
    src='https://www.googletagmanager.com/ns.html?id=GTM-KJ86GWG'
    height='0'
    width='0'
    style='display: none; visibility: hidden'
  ></iframe>`;
    dataLayer.src = '/metrics/gtmDataLayer.js';
    script.src = '/metrics/gtm.js';
    script.onload = () => store.dispatch.metricsModel.setGTMLoaded();
    document.head.append(dataLayer, script);
    document.body.appendChild(noscript);
};
const createMTStag = () => {
    const script = document.createElement('script');
    script.innerHTML = `
    (function (w, d, s, l, u)

{     w[l] = w[l] || [];     w[l].push(\{ 'mtm.start': new Date().getTime(), event: 'mtm.js' }
);
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '?l=' + l : '';
    j.async = true;
    j.src = 'https://tag.a.mts.ru/container/' + u + '/tag.js' + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '54fc11ce-e88c-4f87-a8c8-0b24e513078e');
  `;
    document.head.append(script);
};
/* const createYm = () => {
  const script = document.createElement('script');
  script.src = '/metrics/ym.js';
  script.onload = () => store.dispatch.metricsModel.setYMLoaded();
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<div><img src="https://mc.yandex.ru/watch/88980668" style="position: absolute; left: -9999px" alt="" /></div>`;
  document.head.append(script, noscript);
}; */
const initApp = async () => {
    const isDev = checkDevMode();
    createProfileWidget();
    createSurveyWidget();
    if (!isDev) {
        createGTM();
        createMTStag();
        /* createYm(); */
    }
    render(_jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }), document.getElementById('root'));
};
initApp();
